import { computed } from "vue"
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useCountdownTimer, useStoreAction } from "@/composables"
import { Time } from "@/consts"
import i18n from "@/i18n"

/**
 *
 */
export function usePersonTokenValidation(onFinish = () => {}) {
  // Guest
  const { guest } = useNamespacedState("guest", ["guest"])
  const { currentStep } = useNamespacedState("flow/guest", ["currentStep"])

  // Guest Auth
  const { auth, contacts } = useNamespacedState("guest/auth", [
    "auth",
    "contacts",
  ])
  const { changeAuth, setQualified } = useNamespacedMutations("guest/auth", [
    "changeAuth",
    "setQualified",
  ])
  const { sendPin } = useStoreAction("guest/auth", "sendPin")
  const {
    verifyPin,
    isLoading: isVerifyPinLoading,
    hasError: isPinInvalid,
  } = useStoreAction("guest/auth", "verifyPin")

  // Helpers
  const { sendNotification } = useNamespacedActions("notifications", [
    "sendNotification",
  ])
  const { waitingMinutes, onWaitingTimer, isTimerOn } = useCountdownTimer(90)

  const checkPinValidation = async () => {
    await verifyPin(guest.value)

    if (isPinInvalid.value) {
      sendNotification({
        message: i18n.tc("reservation.invalid_token_message"),
        duration: 2000,
        confirmationText: "Ok",
        color: "red",
      })
    } else {
      setQualified(true)
      onFinish()
    }
  }

  const onResendCode = async () => {
    onWaitingTimer()
    await sendPin(guest.value)
    sendNotification({
      message: i18n.tc("reservation.resend_code_message"),
      duration: 4 * Time.Seconds,
      color: "blue",
    })
  }

  const isLoading = computed(() => {
    if (isVerifyPinLoading.value) {
      return true
    } else {
      return false
    }
  })

  const validationType = computed(() => {
    return auth?.value?.validationMethod?.includes("@") ? "email" : "SMS"
  })

  return {
    auth,
    contacts,
    isLoading,
    validationType,
    waitingMinutes,
    isTimerOn,
    verifyPin,
    changeAuth,
    checkPinValidation,
    onResendCode,
    currentStep,
  }
}
