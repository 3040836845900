<template>
  <token-validation
    :title="$t('reservation.data_validation_access')"
    :description="$t('reservation.person_creation_description')"
    :auth="auth"
    :is-loading="isLoading || isLoadingNext"
    :is-timer-on="isTimerOn"
    :waiting-minutes="waitingMinutes"
    :validation-type="validationType"
    :back-btn-text="$t('reservation.back_button')"
    @back="$router.push({ name: currentStep.route })"
    @change="changeAuth({ pin: $event })"
    @resend="onResendCode"
    @finish="checkPinValidation"
  />
</template>

<script setup>
import { useRouter } from "vue-router/composables"
import {
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers"

import { useStoreAction } from "@/composables"

import TokenValidation from "./components/TokenValidation.vue"
import { usePersonTokenValidation } from "./usePersonTokenValidation"

const { accommodationConfig } = useNamespacedGetters("company", [
  "accommodationConfig",
])
const { guest } = useNamespacedState("guest", ["guest"])
const { next, isLoading: isLoadingNext } = useStoreAction("flow/guest", "next")

const router = useRouter()

const onFinish = async () => {
  if (guest.value.masterGuest && accommodationConfig.value.enabled) {
    router.push({ name: "accommodation" })
  } else {
    await next()
  }
}

const {
  auth,
  changeAuth,
  checkPinValidation,
  currentStep,
  isLoading,
  isTimerOn,
  validationType,
  waitingMinutes,
  onResendCode,
} = usePersonTokenValidation(onFinish)
</script>
